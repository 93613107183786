<template>
  <div class="headers flex2">
    <div class="header-content flex3">
      <div class="logo">
        <img src="@/assets/PC/header/logo_slices/logo.png"/>
      </div>
      <div v-if="clientWidth > 768" class="header-pc flex">
        <!-- 普通导航 -->
        <el-menu v-if="channelState" :default-active="activeRoute" mode="horizontal" router>
          <template v-for="(item, index) in navPath">
            <el-submenu v-if="item.items.length" :index="item.id" :key="item.id" :class="item.activeRoute == activeRoute ? 'is-active' : ''">
              <template slot="title">{{ item.title }}</template>
              <el-menu-item v-for="(items, key) in item.items" :key="key" :index="items.path" :class="(items.id == (index + ($route.query.Id ? $route.query.Id : $route.query.type))) && (item.activeRoute == activeRoute) ? 'is-active' : ''">
                {{ items.title }}
              </el-menu-item>
            </el-submenu>
            <el-menu-item v-else :index="item.path" :key="item.key">
              {{ item.title }}
            </el-menu-item>
          </template>
          <template v-if="token">
            <el-menu-item v-if="clientWidth < 769" :index="InMall.path" :key="InMall.id"> {{ InMall.title }} </el-menu-item>
            <el-menu-item :index="profile.path" :key="profile.id"> {{ profile.title }} </el-menu-item>
          </template>
        </el-menu>

        <!-- 渠道导航 -->
        <el-menu v-else :default-active="activeRoute" mode="horizontal" router>
          <el-menu-item v-if="token" :index="Integral.path" :key="Integral.id"> {{ Integral.title }} </el-menu-item>
        </el-menu>

        <div class="Seats flex2">
          <div class="Seat1 co-fff flex0 cur" @click="LinkGo(innovate.path)">{{ innovate.title }}</div>
          <div class="Seat1 co-fff flex0 cur" @click="LinkGo(newPartner.path)">{{ newPartner.title }}</div>
          <div class="Seat1 co-fff flex0 cur" @click="LinkGo(newPaper.path)">{{ newPaper.title }}</div>
        </div>
      </div>

      <div v-else class="header-mb" ref="nav1">
        <img  class="nav-icon-m" src="@/assets/PC/home/menu_m.png" @click="navMobileState"/>
        <!-- 移动端 导航 -->
        <el-collapse-transition v-if="channelState">
          <div class="nav_m" v-if="navMobile" >
            <ul class="mobileNav">
              <li v-for="(item, index) in navPath" :key="item.key" @click="routePath(item.path)">
                <p v-if="item.items.length == 0" class="menu-item-title" :class="item.path == activeRoute ? 'active3' : ''"> 
                  {{ item.title }} 
                </p>
                <el-popover v-else placement="left-start" trigger="click" content="">
                  <ul class="itemChild">
                    <li v-for="itemChild in item.items" :key="itemChild.key" @click="routePath(itemChild.path)" :class="(itemChild.id == (index + ($route.query.Id ? $route.query.Id : $route.query.type))) && (item.activeRoute == activeRoute) ? 'active3' : ''">{{ itemChild.title }}</li>
                  </ul>
                  <p slot="reference" :class="item.activeRoute == activeRoute ? 'active3' : '' "><i class="el-icon-caret-left"></i> {{item.title}}</p>
                </el-popover>
              </li>
              <li @click="routePath(innovate.path)">
                <p :class="innovate.path == activeRoute ? 'active3' : ''">{{ innovate.title }}</p>
              </li>
              <li @click="routePath(newPartner.path)">
                <p :class="newPartner.path == activeRoute ? 'active3' : ''">{{ newPartner.title }}</p>
              </li>
              <li @click="routePath(newPaper.path)">
                <p :class="newPaper.path == activeRoute ? 'active3' : ''">{{ newPaper.title }}</p>
              </li>
              <template v-if="token">
                <li @click="routePath(InMall.path)">
                  <p :class="InMall.path == activeRoute ? 'active3' : ''">{{ InMall.title }}</p>
                </li>
                <li @click="routePath(profile.path)">
                  <p :class="profile.path == activeRoute ? 'active3' : ''">{{ profile.title }}</p>
                </li>
                <li>
                  <p @click="dropOut(layout.title)">{{ layout.title }}</p>
                </li>
              </template>
              <template v-else>
                <li @click="routePath(login.path + '?redirectUrl='+ $route.fullPath )">
                  <p :class="login.path == activeRoute ? 'active3' : ''">{{ login.title }}</p>
                </li>
                <li @click="routePath(register.path + '?redirectUrl='+ $route.fullPath )">
                  <p :class="register.path == activeRoute ? 'active3' : ''">{{ register.title }}</p>
                </li>
              </template>
            </ul>
          </div>
        </el-collapse-transition>

        <!-- 移动端 渠道导航 -->
        <el-collapse-transition v-else>
          <div class="nav_m" v-if="navMobile" >
            <ul class="mobileNav">
              <li @click="routePath(innovate.path)">
                <p :class="innovate.path == activeRoute ? 'active3' : ''">{{ innovate.title }}</p>
              </li>
              <li @click="routePath(newPartner.path)">
                <p :class="newPartner.path == activeRoute ? 'active3' : ''">{{ newPartner.title }}</p>
              </li>
              <template v-if="token">
                <li @click="routePath(Integral.path)">
                  <p :class="Integral.path == activeRoute ? 'active3' : ''">{{ Integral.title }}</p>
                </li>
                <li>
                  <p @click="dropOut(layout.title)">{{ layout.title }}</p>
                </li>
              </template>
              <template v-else>
                <li @click="routePath(login.path + '?redirectUrl='+ this.$route.fullPath )">
                  <p :class="login.path == activeRoute ? 'active3' : ''">{{ login.title }}</p>
                </li>
                <li @click="routePath(register.path + '?redirectUrl='+ this.$route.fullPath )">
                  <p :class="register.path == activeRoute ? 'active3' : ''">{{ register.title }}</p>
                </li>
              </template>
            </ul>
          </div>
        </el-collapse-transition>
      </div>

      <div v-if="clientWidth > 768 " class="flex0">
        <div v-if="clientWidth > 768 && token && channelState" class="header_left_out_content flex1" @mouseenter="InMalls" @mouseleave="InMalls1">
          <div style="position: relative;">
            <i class="el-icon-bell cur" :class="Infos.isNewMessage || $store.state.activeRoute == '/Home/InMail' ? 'violet': ''"></i>
            <div v-if="Infos.isNewMessage" class="box-msg"></div>
          </div>
          <transition name="el-zoom-in-top">
            <div v-show="IsInMall" class="InMall-content">
              <div v-if="!isData" class="InMallList">
                <div v-for="item in MsgTop4" :key="item.id" class="InMall-item flex hidden cur" @click="InMallText(item.id)">
                  <div class="InMall-item-icon flex0">
                    <img src="../assets/PC/home/znx.png" alt="" width="22px">
                    <div v-if="!item.isReaded" class="box-msg"></div>
                  </div>
                  <div class="InMall-item-msg hidden">
                    <div><b v-if="item.type == 0">系统通知</b></div>
                    <p class="hidden co-999">{{ item.introduction}}</p>
                  </div>
                </div>
                <div class="InMall-btn tc cur" @click="InMallText()">
                  查看所有消息
                </div>
              </div>
              <div v-else class="tc" style="padding: 15px">暂无消息</div>
            </div>
          </transition>
        </div>

        <div class="user">
          <el-dropdown v-if="token" :hide-on-click="false" @command="handleCommand" class="user-login flex0 cur">
            <span class="el-dropdown-link ml hidden" style="outline: none">
              <img src="@/assets/PC/header/user_slices/user1.png" width="20"/>
              {{ Infos.name }}
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="layout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div v-else class="user-login flex0" @click="Login">
            <span class="login-text cur">登录</span>
          </div>
        </div>

        <div>
          <img v-if="channelState" src="@/assets/PC/header/logo_slices/logo_right.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { logout } from "@/api/user.js";
import { GetUserInfo, GetSystemMessageTop4 } from "@/api/profile.js";
import { ApiToken } from '@/utils/ApiToken'

export default {
  name: 'headers',
  data() {
    return {
      //! 导航-创新大赛
      // innovate: { id: '10070', title: "论剑 2021", path: "/Home/Live" },
      // innovate: { id: '10070', title: "巡展活动", path: "/Roadshow" },
      innovate: { id: '10070', title: "研讨会", path: "/WorkshopReview" },
      //! 导航-学习指导
      newPartner: { id: '10080', title: "入门指南", path: "/Home/NewPartner" },
      //! 导航-论文征集
      newPaper: { id: '10081', title: "论文征集", path: "/Paper" },
      //! 导航-站内信
      InMall: { id: '10060', title: "站内信", path: "/Home/InMail" },
      //! 导航-个人中心
      profile: { id: '10090', title: "个人中心", path: "/Home/Profile" },
      //! 导航-退出登录
      layout: {title: "退出账户", key: "99", path: "/Home/index" },
      //! 导航-登录
      login: { title: "登录", path: "/Login" },
      //! 导航-注册
      register: { title: "注册", path: "/Register" },
      //! 导航-我的积分
      Integral: { id: '10071',title: "我的积分", path: "/Home/Integral" },
      //! 移动端导航展示隐藏
      navMobile: false,
      //! 是否显示消息
      IsInMall: false,
      //! 消息是否为空
      isData: false,
    }
  },
  mounted() {
    let _this = this;
    document.addEventListener("click", function (e) {
      if (document.documentElement.clientWidth < 750) {
        if(_this.$refs.nav1){
          if (!!_this.$refs.nav1.contains(e.target)) return;
          _this.navMobile = false;
        }
      }
    });

    this.GetInfo()
  },
  methods: {
    // !获取个人信息
    GetInfo() {
      if(this.token && !this.Infos.name) {
        GetUserInfo().then(res => {
          if(res.status === 1) {
            this.$store.dispatch('Profile', res.data)
            this.$store.commit('channel', res.data.type)
            GetSystemMessageTop4().then(res => {
              if(res.status == 1) {
                this.$store.dispatch('MsgTop4', res.data)
                if(res.data.length == 0) {
                  this.isData = true;
                }
              }else {
                this.$toast(res.message);
              }
            })
          } else {
            this.$toast(res.message)
            this.$router.push('/Login');
            this.$store.commit('$_setStorage', '')
          }
        })
      }
    },
    // !登录、注册
    handleCommand(command) {
      if (command == "layout") {
        logout().then(res => {
          this.loading = true;
          localStorage.setItem('channelType', this.Infos.type)

          this.$store.commit('$_setStorage', '')
          if(this.$route.name.toLowerCase() == 'article' || this.$route.name.toLowerCase() == 'collect' ||this.$route.name.toLowerCase() == 'post' || this.$route.name.toLowerCase() == 'message'){
            if(this.channel == 1) {
              this.$router.push('/Home/Live')
            } else {
              this.$router.push("/home/forum")
            }
            location.reload()
          }else{
            location.reload()
          }
        });
      }
    },
    LinkGo(path){
      this.$router.push(path);
    },
    // !滑入显示站内信
    InMalls() {
      this.IsInMall = true;
    },
    // !滑出隐藏站内信
    InMalls1() {
      this.IsInMall = false;
    },
    // !去站内信页面
    InMallText(id) {
      if(id) {
        this.$router.push('/Home/InMail?id=' + id);
      } else {
        this.$router.push('/Home/InMail');
      }
    },
    // !移动端导航显示隐藏
    navMobileState() {
      this.navMobile = !this.navMobile;
    },
    // ! 移动端页面跳转
    routePath(path){
      if(path){
        this.$router.push(path);
        this.navMobile = false;
      }
    },
    // ! 退出登录
    dropOut(title) {
      if (title == "退出账户") {
        localStorage.setItem('channelType', this.Infos.type)
        localStorage.removeItem(ApiToken.token);
        location.reload();
      }
    },
    // !去登录
    Login() {
      this.$router.push('/Login?redirectUrl='+this.$route.fullPath)
    },
    goLogin() {
      if(this.$route.query.from) {
        this.$router.push('/Login?redirectUrl=' + this.$route.query.redirectUrl + '&from=' + this.$route.query.from)
      } else if (this.channel === 1) {
        this.$router.push('/Login?redirectUrl=' + this.$route.query.redirectUrl)
      } else {
        this.$router.push('/Login?redirectUrl='+this.$route.fullPath)
      }
    },
  },
  computed: {
    ...mapState(['clientWidth', 'activeRoute', 'Module', 'navPath', 'Infos', 'token', 'MsgTop4', 'channel']),

    channelState() {
      return this.channel === -1 || this.channel === 0
    }
  }

}
</script>

<style lang="scss">
  $mobil_base: 750;
  $pc_base: 1200;
  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }
  @function pw($true) {
    @return ($true / $pc_base) * 100vw;
  }

  .headers {
    width: 100%; height: 60px; position: fixed; top: 0; left: 0; z-index: 100001; background: #F8F8F8; border-bottom: 1px #E4E4E4 solid;
    .header-content { width: 1260px; max-width: 1260px; margin: 0 auto; padding: 0 30px }
    .logo img { width: 75px }
    .header-pc .el-menu-item,
    .header-pc .el-menu--horizontal,
    .header-pc .el-menu-item:hover,
    .header-pc .el-submenu__title:hover,
    .header-pc .el-submenu.is-active .el-submenu__title,
    .header-pc .el-menu-item.is-active { border: 0; background: inherit }

    .header-pc .el-submenu__title,
    .header-pc .el-menu-item { padding: 0 8px; font-size: 18px; font-size:16px;}
    .header-pc .el-menu-item.is-active,
    .header-pc .el-submenu__title:hover,
    .header-pc .el-submenu.is-active .el-submenu__title { color: #6b18a8; font-weight: bold }

    .user-login { width: 130px; height: 60px }
    .user-login .el-dropdown-link { line-height: 60px }
    .user-login .el-dropdown-link img {margin-top: -2px }

    .Seat1 { width: 103px; height: 35px; background: #6b18a8; border-radius: 17.5px; font-size: 16px; font-weight: 700; margin-left: 10px; }
    .Seat1:nth-child(1) {margin-left: 15px;}

    .header_right_out_content{margin-left: 20px;}
    .header_left_out_content {width: 100px;height: 60px; position: relative;width:60px;}
    .header_left_out_content i {font-size: 26px; color: #999;}
    .header_left_out_content:hover .el-icon-bell {color: #6b18a8!important;}
    .box-msg {position: absolute; top: 0; right: 0;}
    .header_left_out_content .InMall-content {width: 300px; position: absolute; top: 60px; left: 0; background: #fff; border-radius: 0 0 10px 10px; box-shadow: 0 0 10px #ccc;}
    .InMall-item {padding: 15px 25px; border-bottom: 1px #f2f5f7 solid;}
    .InMall-item:hover {background: rgb(252, 238, 252);}
    .InMall-item-icon {min-width: 42px; width: 42px; height: 42px; border-radius: 50%; background: #F2F5F7; margin-right: 15px; position: relative;}
    .InMall-btn {height: 40px; line-height: 40px; text-decoration: underline;}

    .login-text {
      font-size: 18px;
      color: #666; 
      font-weight: 400
    }
    .login-text:hover {
      color: #6b18a8;
      font-weight: bold;
    }
  }

  @media (max-width: 1260px) {
    .headers{
      .header-pc .el-menu-item { padding: 0 6px; font-size: 16px; }
    }
  }
  @media (max-width: 1200px) {
    .headers{
      .header-content { width: pw(1200); max-width: pw(1200); margin: 0 auto; padding: 0 pw(20) }
      .logo img { width: pw(75) }
      .header-pc .el-menu-item { padding: 0 pw(6); font-size: pw(16);}
      .Seat1 { width: pw(103);font-size: pw(16); margin-left: pw(10);}
      .Seat1:nth-child(1) {margin-left: pw(15);}
      .header_left_out_content {width: pw(80);}
      .user-login { width: pw(100); }
      .header_left_out_content .InMall-content {width: pw(270);}
    }
  }

  @media (max-width: 768px) {
    .headers {
      height: vw(100);z-index: 100002;
      .header-content { padding: 0 vw(20) }
      .logo img { width: vw(110); height: vw(52) }
      .nav-icon-m { display: block; width: vw(38) }
      .nav_m { min-width: 90px; position: absolute; top: vw(100); right: -1px; z-index: 5 }

      .mobileNav{background: #f8f8f8;padding:0 0 vw(12) 0;}
      .mobileNav>li{padding: 0 20px;}
      .mobileNav p{outline: none!important;}
    }
    .itemChild li{line-height: 2;padding: 0 vw(20);}
  }
</style>